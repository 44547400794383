import styled, { css } from 'styled-components'

export const BookingOptionEditStyles = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing(4)};
    .option-content {
      background: ${theme.colors.white};
      display: flex;
      flex-direction: column;
      row-gap: ${theme.spacing(1)};
      padding: ${theme.spacing(2.5)};
    }

    .option-value-title {
      background: ${theme.mixins.opacity(theme.colors.brown, 0.1)};
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.spacing(1.25, 2, 1.25, 1.25)};
    }

    .lg-react-element {
      margin-top: ${theme.spacing(1.5)};
      display: flex;
      flex-flow: wrap;
      column-gap: ${theme.spacing(2)};
      row-gap: ${theme.spacing(2)};

      .option-value-image {
        ${theme.mixins.square(theme.spacing(19))}
      }
    }
  `
)
