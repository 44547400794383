import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue } from '@digital-magic/ts-common-utils'
import { priceCurrency } from '@constants/configuration'
import { BookingHouseFreeOption } from '@api/endpoints/bookings'
import { fileUrl, getLargeThumbnailId } from '@api/endpoints/files'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { Edit } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import { Button } from '@controls/Button'
import { DetailList, DetailListItem } from '@controls/DetailList'
import { ImageGallery, ImageItem } from '@controls/ImageGallery'
import { Text } from '@controls/Text'
import { useEditBookingStore } from '../useEditBookingStore'
import { BookingOptionsStyled } from './BookingOptions.styles'

type Props = {
  freeOptions: ReadonlyArray<BookingHouseFreeOption>
  disabledControls: boolean
}

export const BookingOptions: React.FC<Props> = ({ freeOptions, disabledControls }) => {
  const { t, i18n } = useTranslation()
  const formatCurrency = useCurrencyFormatter()
  const translateValue = useTranslatedValue()
  const editMode = useEditBookingStore((s) => s.editing)
  const startOptionEdit = useEditBookingStore((s) => s.startOptionEdit)
  const options = useEditBookingStore((s) => s.selectedOptions)

  const optionItems = React.useMemo((): ReadonlyArray<DetailListItem> => {
    return options.map((o) => {
      const label = (
        <Box display="flex" flexDirection="row" alignItems="center" columnGap={2}>
          {editMode && (
            <Button size="small" onClick={() => startOptionEdit(o.optionId)} disabled={disabledControls}>
              <Edit fontSize="small" />
            </Button>
          )}
          {`${translateValue(o.name)} (${o.values.map((value) => translateValue(value.name)).join(', ')})`}
        </Box>
      )

      const valueSum = o.values.reduce((sum, value) => sum + Number(value.price), 0)
      const value = formatCurrency(valueSum, priceCurrency)
      const description = o.values[0]?.description
      const images = o.values[0]?.images

      return {
        label,
        value,
        children: (
          <>
            {hasValue(description) && (
              <Text component="div" weight="regular" py={2.5} pl={6} pr={12}>
                {translateValue(description)}
              </Text>
            )}
            {hasValue(images) && images.length > 0 && (
              <ImageGallery selector=".option-value-image">
                {images.map((img, index) => (
                  <ImageItem key={index} className="option-value-image" src={fileUrl(getLargeThumbnailId(img))} />
                ))}
              </ImageGallery>
            )}
          </>
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, i18n.language, editMode, disabledControls])

  return (
    <BookingOptionsStyled>
      <DetailList className="option-list" items={optionItems} />
      <Text display="block" size="size-20" weight="regular" color="brown" mt={5} mb={2.5}>
        {t('pages.booking_summary.free_options.title')}
      </Text>
      <Grid container columnSpacing={6}>
        {/*Move free options to a separate component*/}
        {freeOptions.map((o) => (
          <Grid item key={o.id} xs={12} md={6} lg={4}>
            <Text weight="regular" className="free-option">
              {translateValue(o.name)}
            </Text>
          </Grid>
        ))}
      </Grid>
    </BookingOptionsStyled>
  )
}
