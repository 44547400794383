import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/ReadonlyArray'
import { pipe } from 'fp-ts/function'
import { OptionalType } from '@digital-magic/ts-common-utils'
import { BookingOption } from '@api/endpoints/bookings'
import { HouseOptionView } from '@api/endpoints/buildings/houses'
import { LayoutTypeOptionValueId } from '@api/endpoints/buildings/layoutTypes'

export const extractLayoutTypeOptionValueIds = (
  availableOptions: ReadonlyArray<HouseOptionView>,
  selectedOptions: ReadonlyArray<BookingOption>
): ReadonlyArray<LayoutTypeOptionValueId> =>
  pipe(
    availableOptions,
    A.flatMap((o) => o.values),
    (availableOptionValues) =>
      pipe(
        selectedOptions,
        A.flatMap((o) =>
          pipe(
            o.values,
            A.map((v) => v.optionValueId)
          )
        ),
        A.map((optionValueId) =>
          pipe(
            availableOptionValues,
            A.findFirst((v) => v.optionValueId === optionValueId),
            O.map((v) => v.layoutTypeOptionValueId)
          )
        ),
        A.compact
      )
  )

export const hasSelectedBookingOptionsErrors = (
  selectedOptions: ReadonlyArray<BookingOption>,
  editingOption: OptionalType<HouseOptionView>
): boolean =>
  pipe(
    O.fromNullable(editingOption),
    O.flatMap((o) => (o.multiValue ? O.none : O.some(o))),
    O.map((eo) =>
      pipe(
        selectedOptions,
        A.findFirst((o) => o.optionId === eo.optionId),
        O.fold(
          () => true,
          (o) => o.values.length === 0
        )
      )
    ),
    O.getOrElse(() => false)
  )
