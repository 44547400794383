import * as React from 'react'
import { HtmlInputChangeEventHandler } from '@digital-magic/react-common'
import { priceCurrency } from '@constants/configuration'
import { HouseOptionValueView } from '@api/endpoints/buildings/houses'
import { OptionId } from '@api/endpoints/buildings/options'
import { fileUrl, getLargeThumbnailId } from '@api/endpoints/files'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { useTranslatedFileId } from '@hooks/useTranslatedFileId'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { Box } from '@mui/material'
import { Checkbox } from '@controls/Checkbox'
import { DownloadLinkByFileId } from '@controls/DownloadLinkByFileId'
import { ImageGallery, ImageItem } from '@controls/ImageGallery'
import { Text } from '@controls/Text'
import { useEditBookingStore } from '@pages/Customer/BookingSummary/useEditBookingStore'
import DownloadPdf from '@icons/DownloadPdf.png'

type Props = Readonly<{
  optionId: OptionId
  optionValue: HouseOptionValueView
  multiValue: boolean
  checked: boolean
  disabledControls: boolean
}>

export const BookingOptionValue: React.FC<Props> = ({
  optionId,
  optionValue,
  multiValue,
  checked,
  disabledControls
}) => {
  const translateValue = useTranslatedValue()
  const formatCurrency = useCurrencyFormatter()
  const translateFileId = useTranslatedFileId()

  const setOptionValue = useEditBookingStore((s) => s.setOptionValue)
  const addOptionValue = useEditBookingStore((s) => s.addOptionValue)
  const removeOptionValue = useEditBookingStore((s) => s.removeOptionValue)

  const formattedPrice = formatCurrency(Number(optionValue.price), priceCurrency)

  const onOptionValueSelect: HtmlInputChangeEventHandler = (e) => {
    if (e.target.checked) {
      if (multiValue) {
        addOptionValue(optionId, optionValue.optionValueId)
      } else {
        setOptionValue(optionId, optionValue.optionValueId)
      }
    } else {
      removeOptionValue(optionId, optionValue.optionValueId)
    }
  }

  return (
    <div>
      <div className="option-value-title">
        <Checkbox
          label={<Text weight="semibold">{translateValue(optionValue.name)}</Text>}
          onChange={onOptionValueSelect}
          checked={checked}
          disabled={disabledControls}
        />
        <Text weight="semibold">{formattedPrice}</Text>
      </div>
      {optionValue.description && (
        <Text component="div" weight="regular" py={2.5} pl={6} pr={12}>
          {translateValue(optionValue.description)}
        </Text>
      )}
      {optionValue.documentId && (
        <Box py={2.5} pl={6} pr={12}>
          <DownloadLinkByFileId fileId={translateFileId(optionValue.documentId)}>
            <img src={DownloadPdf} title="Download PDF" alt="Download PDF" height={32} />
          </DownloadLinkByFileId>
        </Box>
      )}
      {optionValue.images.length > 0 && (
        <ImageGallery selector=".option-value-image">
          {optionValue.images
            .concat()
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((img, index) => (
              <ImageItem key={index} className="option-value-image" src={fileUrl(getLargeThumbnailId(img))} />
            ))}
        </ImageGallery>
      )}
    </div>
  )
}
