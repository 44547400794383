import * as React from 'react'
import { BookingHouse } from '@api/endpoints/bookings'
import { ImageFileEntity, fileUrl, getLargeThumbnailId } from '@api/endpoints/files'
import { ImageGallery, ImageItem } from '@controls/ImageGallery'
import { BookingImagesStyled } from './BookingImages.styles'

type Props = Readonly<{
  house: BookingHouse
}>

export const BookingImages: React.FC<Props> = ({ house }) => {
  const images = React.useMemo(
    (): ReadonlyArray<ImageFileEntity> => [
      ...house.planImages.concat().sort((a, b) => a.orderNumber - b.orderNumber),
      ...house.images.concat().sort((a, b) => a.orderNumber - b.orderNumber)
    ],
    [house]
  )

  return (
    <BookingImagesStyled>
      <ImageGallery selector=".house-images">
        {images.map((img, index) => (
          <ImageItem key={index} src={fileUrl(getLargeThumbnailId(img))} className="house-images" />
        ))}
      </ImageGallery>
    </BookingImagesStyled>
  )
}
