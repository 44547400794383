import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'

export const OfferBy: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Text display="block" color="brown" size="size-34" my={4}>
        {t('pages.booking_summary.offer_by.title')}
      </Text>
      <Text size="size-14" color="black">
        {t('app.main.contacts.representative.name')}
        <br />
        {t('app.main.contacts.representative.role')}
        <br />
        <Link href={`tel: ${t('app.main.contacts.representative.phone')}`} target="_blank" rel="noopener">
          {t('app.main.contacts.representative.phone')}
        </Link>
        <br />
        <Link href={`mailto: ${t('app.main.contacts.representative.email')}`} target="_blank" rel="noopener">
          {t('app.main.contacts.representative.email')}
        </Link>
      </Text>
    </div>
  )
}
