import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HouseOptionView } from '@api/endpoints/buildings/houses'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { KeyboardReturn } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Button } from '@controls/Button'
import { Text } from '@controls/Text'
import { useEditBookingStore } from '@pages/Customer/BookingSummary/useEditBookingStore'
import { BookingOptionEditStyles } from './BookingOptionEdit.styles'
import { BookingOptionValue } from './BookingOptionValue'

type Props = React.PropsWithChildren & {
  option: HouseOptionView
  disabledControls: boolean
}

export const BookingOptionEdit: React.FC<Props> = ({ option, disabledControls }) => {
  const { t } = useTranslation()
  const translateValue = useTranslatedValue()
  const completeOptionEdit = useEditBookingStore((s) => s.completeOptionEdit)
  const selectedOptionValueIds = useEditBookingStore((s) =>
    s.selectedOptions.filter((o) => o.optionId === option.optionId).flatMap((o) => o.values.map((v) => v.optionValueId))
  )

  const requiredOption = !option.multiValue
  const validationFailed = requiredOption && selectedOptionValueIds.length === 0

  return (
    <BookingOptionEditStyles>
      <Text weight="regular" size="size-16">
        {translateValue(option.name)}
        {requiredOption && (
          // eslint-disable-next-line i18next/no-literal-string
          <Text color="error" fontWeight="bold">
            *
          </Text>
        )}
        <div className="option-content">
          {option.values.map((v, i) => (
            <BookingOptionValue
              key={i}
              checked={selectedOptionValueIds.includes(v.optionValueId)}
              optionValue={v}
              optionId={option.optionId}
              multiValue={option.multiValue}
              disabledControls={disabledControls}
            />
          ))}
        </div>
      </Text>
      {validationFailed && (
        <Box mb={3} pl={2.5}>
          <Text weight="bold" color="error" size="size-16">
            {t('pages.booking.personalization.options.messages.required_fields')}
          </Text>
        </Box>
      )}
      <Box>
        <Button size="small" disabled={disabledControls || validationFailed} onClick={completeOptionEdit}>
          <KeyboardReturn />
        </Button>
      </Box>
    </BookingOptionEditStyles>
  )
}
