import styled, { css } from 'styled-components'

export const BookingStyleStyled = styled('div')(
  ({ theme }) => css`
    .styling-card {
      padding: ${theme.spacing(2.5)};
      background: ${theme.mixins.opacity(theme.colors.brown, 0.15)};

      .styling-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  `
)
