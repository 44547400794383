import styled, { css } from 'styled-components'

export const BookingOptionsStyled = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacing(3, 0)};

    .option-list {
      .detail-value {
        font-weight: ${theme.typography.fontWeightMedium};
      }
    }

    .free-option {
      font-size: ${theme.global.typography.size['size-14']};
      border-bottom: 1px solid ${theme.colors.grey600};
      width: 100%;
      height: 100%;
      display: block;
      padding: ${theme.spacing(1.5, 0)};
    }

    .lg-react-element {
      margin-top: ${theme.spacing(1.5)};
      display: flex;
      flex-flow: wrap;
      column-gap: ${theme.spacing(2)};
      row-gap: ${theme.spacing(2)};

      .option-value-image {
        ${theme.mixins.square(theme.spacing(19))}
      }
    }
  `
)
