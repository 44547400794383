import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import * as IO from 'fp-ts/IO'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/ReadonlyArray'
import { flow, pipe } from 'fp-ts/function'
import * as N from 'fp-ts-std/Number'
import { create } from 'zustand'
import { Handler } from '@digital-magic/ts-common-utils'
import { editBookingStorageKey } from '@constants/configuration'
import {
  BookingOption,
  BookingOptionValue,
  BookingStyle,
  filterEmptyAndSortBookingOptions
} from '@api/endpoints/bookings'
import {
  HouseOptionValueView,
  HouseOptionView,
  HouseStyleView,
  sortHouseStyleImagesByOrderByOrderNumber
} from '@api/endpoints/buildings/houses'
import { LayoutTypeStyleId } from '@api/endpoints/buildings/layoutTypes'
import {
  OptionId,
  OptionValueId,
  diffOptionValueLikeByOptionValueId,
  eqOptionValueId,
  filterDisabledAndSortOptions,
  flattenOption
} from '@api/endpoints/buildings/options'

type AvailableHouseOptions = Readonly<{
  styles: ReadonlyArray<HouseStyleView>
  options: ReadonlyArray<HouseOptionView>
  //basePrice: number
}>

type BookingOptions = Readonly<{
  options: ReadonlyArray<BookingOption>
  style: BookingStyle | undefined
}>

type State = Readonly<{
  editing: boolean
  loading: boolean
  availableHouseOptions: AvailableHouseOptions
  bookingOptions: BookingOptions
  editingOption?: HouseOptionView
  selectedLayoutTypeStyleId?: LayoutTypeStyleId
  selectedOptions: ReadonlyArray<BookingOption>
  updatedOptionsCount: number
}>

type Actions = Readonly<{
  startEdit: IO.IO<void>
  setAvailableHouseOptions: Handler<AvailableHouseOptions>
  setBookingOptions: Handler<BookingOptions>
  setSelectedOptions: Handler<ReadonlyArray<BookingOption>>
  startOptionEdit: Handler<OptionId>
  completeOptionEdit: IO.IO<void>
  setSelectedLayoutTypeStyleId: Handler<LayoutTypeStyleId>
  setOptionValue: (option: OptionId, optionValueId: OptionValueId) => void
  addOptionValue: (option: OptionId, optionValueId: OptionValueId) => void
  removeOptionValue: (option: OptionId, optionValueId: OptionValueId) => void
  cancelEdit: IO.IO<void>
  startLoading: IO.IO<void>
  completeLoading: IO.IO<void>
}>

const emptyState: State = {
  availableHouseOptions: {
    options: [],
    styles: []
  },
  bookingOptions: {
    options: [],
    style: undefined
  },
  editing: false,
  loading: false,
  editingOption: undefined,
  selectedLayoutTypeStyleId: undefined,
  selectedOptions: [],
  updatedOptionsCount: 0
}

const buildBookingOptionValue = (value: HouseOptionValueView): BookingOptionValue => ({
  optionValueId: value.optionValueId,
  name: value.name,
  description: value.description,
  images: value.images,
  price: value.price,
  orderNumber: value.orderNumber
})

const buildBookingOption = (option: HouseOptionView): BookingOption => ({
  code: option.code,
  multiValue: option.multiValue,
  name: option.name,
  optionId: option.optionId,
  orderNumber: option.orderNumber,
  values: option.values.map(buildBookingOptionValue)
})

const findOptionAndValue: (
  optionId: OptionId,
  optionValueId: OptionValueId
) => (as: ReadonlyArray<HouseOptionView>) => O.Option<[BookingOption, BookingOptionValue]> = (
  optionId: OptionId,
  optionValueId: OptionValueId
) =>
  flow(
    A.findFirst((o: HouseOptionView) => o.optionId === optionId),
    O.map(buildBookingOption),
    O.flatMap((o: BookingOption) =>
      pipe(
        o.values,
        A.findFirst((v: BookingOptionValue) => v.optionValueId === optionValueId),
        O.map((v: BookingOptionValue) => [o, v])
      )
    )
  )

const calcUpdatedOptionsCount: (state: State) => number = (state) =>
  pipe(
    [
      pipe(state.selectedOptions, A.flatMap(flattenOption)),
      pipe(state.bookingOptions.options, A.flatMap(flattenOption))
    ],
    ([selectedValues, bookingValues]) =>
      pipe(
        diffOptionValueLikeByOptionValueId(selectedValues, bookingValues),
        A.concat(diffOptionValueLikeByOptionValueId(bookingValues, selectedValues)),
        A.map((v) => v.optionId),
        A.uniq(eqOptionValueId),
        A.size,
        N.add(state.selectedLayoutTypeStyleId === state.bookingOptions.style?.layoutTypeStyleId ? 0 : 1)
      )
  )

export const useEditBookingStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...emptyState,
        startEdit: () => set({ editing: true }),
        setAvailableHouseOptions: (availableHouseOptions) =>
          set({
            availableHouseOptions: {
              options: filterDisabledAndSortOptions(availableHouseOptions.options),
              styles: sortHouseStyleImagesByOrderByOrderNumber(availableHouseOptions.styles)
            }
          }),
        setBookingOptions: (bookingOptions) =>
          set((state) => ({
            bookingOptions: {
              options: filterEmptyAndSortBookingOptions(bookingOptions.options),
              style: bookingOptions.style,
              updatedOptionCount: calcUpdatedOptionsCount({ ...state, bookingOptions })
            }
          })),
        setSelectedLayoutTypeStyleId: (selectedLayoutTypeStyleId) =>
          set((state) => ({
            selectedLayoutTypeStyleId,
            updatedOptionsCount: calcUpdatedOptionsCount({ ...state, selectedLayoutTypeStyleId })
          })),
        setSelectedOptions: (selectedOptions) =>
          set((state) => ({
            selectedOptions: filterEmptyAndSortBookingOptions(selectedOptions),
            updatedOptionsCount: calcUpdatedOptionsCount({ ...state, selectedOptions })
          })),
        startOptionEdit: (optionId) =>
          set((state) => ({ editingOption: state.availableHouseOptions.options.find((o) => o.optionId === optionId) })),
        completeOptionEdit: () => set({ editingOption: undefined }),
        setOptionValue: (optionId, optionValueId) =>
          set((state) =>
            pipe(
              state.selectedOptions,
              (options) =>
                pipe(
                  state.availableHouseOptions.options,
                  findOptionAndValue(optionId, optionValueId),
                  O.fold(
                    () => options,
                    ([option, value]) =>
                      pipe(
                        options,
                        A.filter((o) => o.optionId !== optionId),
                        A.append({ ...option, values: [value] })
                      )
                  )
                ),
              (selectedOptions) => ({
                selectedOptions,
                updatedOptionsCount: calcUpdatedOptionsCount({ ...state, selectedOptions })
              })
            )
          ),
        addOptionValue: (optionId, optionValueId) =>
          set((state) =>
            pipe(
              state.selectedOptions,
              (options) =>
                pipe(
                  state.availableHouseOptions.options,
                  findOptionAndValue(optionId, optionValueId),
                  O.fold(
                    () => options,
                    ([option, value]) =>
                      pipe(
                        options,
                        A.findFirst((o) => o.optionId === optionId),
                        O.fold(
                          () => options.concat({ ...option, values: [value] }),
                          (o) =>
                            pipe(
                              options,
                              A.filter((o) => o.optionId !== optionId),
                              A.append({ ...o, values: [...o.values, value] })
                            ).concat()
                        )
                      )
                  )
                ),
              (selectedOptions) => ({
                selectedOptions,
                updatedOptionsCount: calcUpdatedOptionsCount({ ...state, selectedOptions })
              })
            )
          ),
        removeOptionValue: (optionId, optionValueId) =>
          set((state) =>
            pipe(
              state.selectedOptions,
              A.map((o) =>
                o.optionId === optionId
                  ? {
                      ...o,
                      values: pipe(
                        o.values,
                        A.filter((v) => v.optionValueId !== optionValueId)
                      ).concat()
                    }
                  : o
              ),
              A.filter((o) => o.values.length > 0),
              (selectedOptions) => ({
                selectedOptions,
                updatedOptionsCount: calcUpdatedOptionsCount({ ...state, selectedOptions })
              })
            )
          ),
        cancelEdit: () =>
          set((state) => ({
            ...emptyState,
            availableHouseOptions: state.availableHouseOptions,
            bookingOptions: state.bookingOptions,
            selectedLayoutTypeStyleId: state.bookingOptions.style?.layoutTypeStyleId,
            selectedOptions: state.bookingOptions.options
          })),
        startLoading: () => set(() => ({ loading: true })),
        completeLoading: () => set(() => ({ loading: false }))
      }),
      {
        name: editBookingStorageKey,
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)
