import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { priceCurrency } from '@constants/configuration'
import { Booking } from '@api/endpoints/bookings'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { DetailList, DetailListItem } from '@controls/DetailList'
import { Text } from '@controls/Text'
import { BookingPriceStyled } from './BookingPrice.styles'

type Props = {
  booking: Booking
}

export const BookingPrice: React.FC<Props> = ({ booking }) => {
  const { t } = useTranslation()
  const formatCurrency = useCurrencyFormatter()

  const detailItems: ReadonlyArray<DetailListItem> = React.useMemo(
    () => [
      {
        label: t('pages.booking_summary.prices.base'),
        value: formatCurrency(Number(booking.basePrice), priceCurrency)
      },
      {
        label: t('pages.booking_summary.prices.extras'),
        value: formatCurrency(Number(booking.totalPrice) - Number(booking.basePrice), priceCurrency)
      },
      {
        label: t('pages.booking_summary.prices.total'),
        value: (
          <Text weight="regular" size="size-34" color="brown">
            {formatCurrency(Number(booking.totalPrice), priceCurrency)}
          </Text>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [booking, t]
  )

  return (
    <BookingPriceStyled>
      <Text display="block" color="brown" size="size-34" my={4}>
        {t('pages.booking_summary.prices.label')}
      </Text>
      <DetailList items={detailItems} />
    </BookingPriceStyled>
  )
}
