import styled, { css } from 'styled-components'
import { darken, lighten } from '@mui/material'

export const BookingSummaryStyled = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing(4, 4)};
    background: ${theme.colors.grey200};
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;

    .summary-card {
      padding: ${theme.spacing(5, 4)};
      background: ${theme.colors.white};
    }

    button {
      background: ${theme.colors.brown};
      color: ${theme.colors.white};
      white-space: nowrap;
      border: none;

      &,
      .btn-loader {
        color: ${theme.colors.white};
      }

      &:hover,
      &:focus {
        background: ${darken(theme.colors.brown, 0.1)};
      }

      &:disabled {
        color: ${theme.colors.grey400};
        background: ${lighten(theme.colors.brown, 0.3)};
      }
    }
  `
)
