import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { isHttpError } from '@digital-magic/react-common/lib/api'
import { OptionalType, hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import { ErrorPageType } from '@constants/errorPageType'
import { routes } from '@constants/routes'
import { RequestErrorHandler } from '@api/types'
import { BookingId, BookingState, useSubmitBooking, useUpdateBooking } from '@api/endpoints/bookings'
import { useGetBooking } from '@api/endpoints/bookings/api'
import { EmailVerificationSessionId, useStartEmailVerification } from '@api/endpoints/public'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { useVerificationSessionStore } from '@stores/useVerificationSessionStore'
import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Header } from '@layout/CustomerLayout/Header'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { CircleLoader } from '@controls/CircleLoader'
import { useAdminContext } from '@pages/Admin/AdminContext'
import { BookingSuccessPanel } from '@pages/Customer/BookingSuccessPanel/BookingSuccessPanel'
import { BookingContact } from '@pages/Customer/Common/BookingContact'
import { EmailVerification } from '@pages/Customer/EmailVerification/EmailVerification'
import { BookingSummaryStyled } from './BookingSummary.styles'
import { BookingSummaryContent } from './BookingSummaryContent'
import { useEditBookingStore } from './useEditBookingStore'
import { extractLayoutTypeOptionValueIds } from './utils'

type Params = {
  bookingId: BookingId
}

enum VerificationPurpose {
  SubmitBooking,
  EditBooking
}

// TODO: Separate this component to smaller ones. The best way is to hide actions to child components to avoid unintentional renders.
export const BookingSummary: React.FC = () => {
  const { t } = useTranslation()
  const params = useParams<Params>()
  const navigate = useNavigate()
  const defaultErrorHandler = useDefaultErrorHandler()
  const { isLoggedIn } = useAdminContext()
  const [showSubmitSuccess, setShowSubmitSuccess] = React.useState(false)
  const [verificationPurpose, setVerificationPurpose] = React.useState<OptionalType<VerificationPurpose>>()

  const { verificationSessionId, setVerificationSessionId, resetVerificationSessionId } = useVerificationSessionStore()
  const isEditing = useEditBookingStore((s) => s.editing)
  const updatedOptionsCount = useEditBookingStore((s) => s.updatedOptionsCount)
  const selectedLayoutTypeOptionValueId = useEditBookingStore((s) =>
    extractLayoutTypeOptionValueIds(s.availableHouseOptions.options, s.selectedOptions)
  )
  const selectedLayoutTypeStyleId = useEditBookingStore((s) => s.selectedLayoutTypeStyleId)
  const cancelEdit = useEditBookingStore((s) => s.cancelEdit)

  const getBookingErrorHandler: RequestErrorHandler = (e) => {
    if (isHttpError(e)) {
      if (e.httpStatus === 403) {
        return navigate(generatePath(routes.Error, { errorType: ErrorPageType.enum.BookingExpired }))
      }
      if (e.httpStatus == 404) {
        return navigate(generatePath(routes.Error, { errorType: ErrorPageType.enum.BookingNotFound }))
      }
    } else {
      return defaultErrorHandler(e)
    }
  }

  const getBooking = useGetBooking(params.bookingId ?? '', {
    onError: getBookingErrorHandler,
    enabled: hasValue(params.bookingId)
  })
  const booking = getBooking.data

  const onVerificationError: RequestErrorHandler = () => {
    resetVerificationSessionId()
    startEmailVerification.reset()
  }

  const onStartEmailVerificationError: RequestErrorHandler = (e) => {
    onVerificationError(e)
    defaultErrorHandler(e)
  }

  const onStartEmailVerificationSuccess = (): void => {
    setTimeout(() => {
      const element = document.getElementById('email-verification-form')
      element?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  const onSubmitBookingSuccess = async (): Promise<void> => {
    await getBooking.refetch()
    setShowSubmitSuccess(true)
  }

  const onUpdateBookingSuccess = async (): Promise<void> => {
    cancelEdit()
    await getBooking.refetch()
  }

  const onBookingUpdateError: RequestErrorHandler = (e) => {
    resetVerificationSessionId()
    defaultErrorHandler(e)
  }

  const onBookingSubmitError: RequestErrorHandler = (e) => {
    resetVerificationSessionId()
    defaultErrorHandler(e)
  }

  const startEmailVerification = useStartEmailVerification({
    onError: onStartEmailVerificationError,
    onSuccess: onStartEmailVerificationSuccess
  })
  const updateBooking = useUpdateBooking({ onError: onBookingUpdateError, onSuccess: onUpdateBookingSuccess })
  const submitBooking = useSubmitBooking({ onError: onBookingSubmitError, onSuccess: onSubmitBookingSuccess })

  const verifyEmail: React.DispatchWithoutAction = () => {
    if (booking) {
      startEmailVerification.mutate({ emailAddress: booking.customer.email, language: booking.customer.language })
    }
  }

  const completeEmailVerification: React.DispatchWithoutAction = () => {
    if (startEmailVerification.data?.id) {
      setVerificationSessionId(startEmailVerification.data.id)
      setVerificationPurpose(undefined)
      switch (verificationPurpose) {
        case VerificationPurpose.SubmitBooking:
          return completeBookingSubmit(startEmailVerification.data.id)
        case VerificationPurpose.EditBooking:
          return completeBookingEdit(startEmailVerification.data.id)
        case undefined:
          // eslint-disable-next-line no-console
          return console.error('Unexpected verification purpose on completeEmailVerification')
      }
    }
  }

  const completeBookingEdit = (verificationSessionId: OptionalType<EmailVerificationSessionId>): void => {
    if (booking) {
      startEmailVerification.reset()
      updateBooking.mutate({
        id: booking.id,
        verificationSessionId,
        customer: undefined,
        layoutTypeStyleId: selectedLayoutTypeStyleId,
        options: selectedLayoutTypeOptionValueId.concat()
      })
    }
  }

  const onBookingSubmit = (confirmResult: boolean): void => {
    if (confirmResult) {
      if (isLoggedIn || hasValue(verificationSessionId)) {
        completeBookingSubmit(verificationSessionId)
      } else {
        setVerificationPurpose(VerificationPurpose.SubmitBooking)
        verifyEmail()
      }
    }
  }

  const completeBookingSubmit = (verificationSessionId: OptionalType<EmailVerificationSessionId>): void => {
    if (booking) {
      startEmailVerification.reset()
      submitBooking.mutate({
        id: booking.id,
        verificationSessionId
      })
    }
  }

  const onBookingEdit = (): void => {
    if (isLoggedIn || hasValue(verificationSessionId)) {
      completeBookingEdit(verificationSessionId)
    } else {
      setVerificationPurpose(VerificationPurpose.EditBooking)
      verifyEmail()
    }
  }

  const loading =
    getBooking.isLoading || updateBooking.isLoading || submitBooking.isLoading || startEmailVerification.isLoading

  return (
    // TODO: Make layout the same way as for Booking (everything inside Styled section, should improve overall styling)
    <>
      {!isLoggedIn && <Header bgType="light" autoHide />}
      <BookingSummaryStyled>
        <div id="summary-card" className="summary-card">
          <Grid container columnSpacing={{ sm: 4, md: 10, lg: 20 }}>
            <CircleLoader
              loading={getBooking.isLoading}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              {booking && (
                <BookingSummaryContent
                  booking={booking}
                  disabledControls={loading || hasValue(startEmailVerification.data)}
                  onSubmitEdit={onBookingEdit}
                />
              )}
            </CircleLoader>
          </Grid>
        </div>
        {booking &&
          (showSubmitSuccess ? (
            <Box mt={9}>
              <BookingSuccessPanel bookingId={booking.id} />
            </Box>
          ) : (
            <Box mt={3} display="flex" alignContent="space-between" flexDirection="column">
              {booking.state === BookingState.enum.Draft &&
                isEmpty(startEmailVerification.data) &&
                !isEditing &&
                updatedOptionsCount === 0 && (
                  <ButtonWithConfirmation
                    disabled={loading}
                    onConfirmResult={onBookingSubmit}
                    confirmTitle={t('pages.booking_summary.consent.submit.title')}
                    confirmMessage={t('pages.booking_summary.consent.submit.message')}
                  >
                    {t('pages.booking_summary.buttons.submit')}
                  </ButtonWithConfirmation>
                )}
              {hasValue(startEmailVerification.data) && (
                <div id="email-verification-form">
                  <EmailVerification
                    verificationSessionId={startEmailVerification.data.id}
                    loading={loading}
                    onSuccess={completeEmailVerification}
                    onError={onVerificationError}
                  />
                </div>
              )}
            </Box>
          ))}
        {!isLoggedIn && <BookingContact />}
      </BookingSummaryStyled>
    </>
  )
}
