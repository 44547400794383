import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { pipe } from 'fp-ts/function'
import { hasValue, map } from '@digital-magic/ts-common-utils'
import { toSquareMeters } from '@utils/formatting-utils'
import { TranslatedString } from '@api/endpoints/types'
import { Booking } from '@api/endpoints/bookings'
import {
  EnergyClass,
  FormOfOwnership,
  PropertyType,
  useGetEnergyClasses,
  useGetFormsOfOwnership,
  useGetPropertyTypes,
  useGetSettings
} from '@api/endpoints/settings'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { DetailListItem } from '@controls/DetailList'
import { Link } from '@controls/Link'

type UseHouseDataResult = Readonly<{
  detailItems: ReadonlyArray<DetailListItem>
}>

const isIncludedTranslationBuilder =
  (t: TFunction) =>
  (value: boolean): string =>
    value
      ? t('pages.booking_summary.house_details.messages.yes_included')
      : t('pages.booking_summary.house_details.messages.no_not_included')

export const useHouseData = (booking: Booking): UseHouseDataResult => {
  const { t } = useTranslation()

  const translateValue = useTranslatedValue()
  const defaultErrorHandler = useDefaultErrorHandler()

  const getEnergyClasses = useGetEnergyClasses({ onError: defaultErrorHandler })
  const getFormsOfOwnership = useGetFormsOfOwnership({ onError: defaultErrorHandler })
  const getPropertyTypes = useGetPropertyTypes({ onError: defaultErrorHandler })
  const getProjectSettings = useGetSettings({ onError: defaultErrorHandler })

  const detailItems: ReadonlyArray<DetailListItem> = React.useMemo(
    () =>
      pipe(isIncludedTranslationBuilder(t), (isIncludedValue) =>
        [
          {
            label: t('pages.booking_summary.house_details.address') + ' #1',
            value: `${booking.house.building.address}${
              booking.house.apartmentNumber ? ` - ${booking.house.apartmentNumber}` : ''
            }`
          },
          {
            label: t('pages.booking_summary.house_details.address') + ' #2',
            value: booking.house.building.address2
          },
          {
            label: t('pages.booking_summary.house_details.house_type.label'),
            value: t(`pages.booking_summary.house_details.house_type.${booking.house.building.type.code}`)
          },
          {
            label: t('pages.booking_summary.house_details.facade_color'),
            value: t(`enums.facade_color.${booking.house.building.facadeColor}`)
          },
          {
            label: t('pages.booking_summary.house_details.cadastre_number'),
            value: (
              <Link href={`https://xgis.maaamet.ee/ky/${booking.house.cadastreNumber}`} target="_blank" rel="noopener">
                {booking.house.cadastreNumber}
              </Link>
            )
          },
          { label: t('pages.booking_summary.house_details.floors'), value: booking.house.building.floorsCount },
          { label: t('pages.booking_summary.house_details.rooms_count'), value: booking.house.roomsCount },
          { label: t('pages.booking_summary.house_details.bedrooms_count'), value: booking.house.bedroomsCount },
          {
            label: t('pages.booking_summary.house_details.outdoor_size'),
            value: toSquareMeters(booking.house.outdoorSquare)
          },
          {
            label: t('pages.booking_summary.house_details.indoor_size'),
            value: toSquareMeters(booking.house.indoorSquare)
          },
          {
            label: t('pages.booking_summary.house_details.storeroom_size'),
            value: toSquareMeters(booking.house.storeRoomSquare)
          },
          {
            label: t('pages.booking_summary.house_details.terrace_size'),
            value: toSquareMeters(booking.house.terraceSquare)
          },
          { label: t('pages.booking_summary.house_details.sauna'), value: isIncludedValue(booking.house.hasSauna) },
          {
            label: t('pages.booking_summary.house_details.interior'),
            value: isIncludedValue(booking.house.isInteriorIncluded)
          },
          {
            label: t('pages.booking_summary.house_details.sanitary'),
            value: isIncludedValue(booking.house.isSanitaryIncluded)
          },
          {
            label: t('pages.booking_summary.house_details.energy_class'),
            value:
              map((v: EnergyClass) => translateValue(v.title))(
                getEnergyClasses.data?.find((v) => v.id === booking.house.energyClassId)
              ) ?? '-'
          },
          {
            label: t('pages.booking_summary.house_details.access_to_property'),
            value: map((v?: TranslatedString) => (v ? translateValue(v) : '-'))(
              booking.house.building.accessToProperty ?? getProjectSettings.data?.accessToProperty
            )
          },
          {
            label: t('pages.booking_summary.house_details.access_in_property'),
            value: map((v?: TranslatedString) => (v ? translateValue(v) : '-'))(
              booking.house.building.accessInProperty ?? getProjectSettings.data?.accessInProperty
            )
          },
          {
            label: t('pages.booking_summary.house_details.form_of_ownership'),
            value:
              map((v: FormOfOwnership) => translateValue(v.title))(
                getFormsOfOwnership.data?.find((v) => v.id === booking.house.building.formOfOwnershipId)
              ) ?? '-'
          },
          {
            label: t('pages.booking_summary.house_details.property_type'),
            value:
              map((v: PropertyType) => translateValue(v.title))(
                getPropertyTypes.data?.find((v) => v.id === booking.house.building.propertyTypeId)
              ) ?? '-'
          },
          {
            label: t('pages.booking_summary.house_details.immovable_number'),
            value: booking.house.immovableNumber ?? '-'
          },
          {
            label: t('pages.booking_summary.project_info.encumbrances'),
            value: map((v?: TranslatedString) => (v ? translateValue(v) : '-'))(
              booking.house.building.encumbrances ?? getProjectSettings.data?.encumbrances
            )
            //booking.house.building.encumbrances ? translateValue()  hasValue(projectSettings) ? translateValue(projectSettings.encumbrances) : '-'
          },
          {
            label: t('pages.booking_summary.project_info.developer'),
            value: hasValue(getProjectSettings.data) ? translateValue(getProjectSettings.data.developer) : '-'
          },
          {
            label: t('pages.booking_summary.project_info.main_contractor'),
            value: hasValue(getProjectSettings.data) ? translateValue(getProjectSettings.data.mainContractor) : '-'
          },
          {
            label: t('pages.booking_summary.project_info.web_page'),
            value: hasValue(getProjectSettings.data) ? (
              <Link href={getProjectSettings.data.webPage}>{getProjectSettings.data.webPage}</Link>
            ) : (
              '-'
            )
          },
          {
            label: t('pages.booking_summary.project_info.construction_timeline'),
            value: hasValue(getProjectSettings.data)
              ? translateValue(getProjectSettings.data.constructionTimeline)
              : '-'
          },
          {
            label: t('pages.booking_summary.project_info.building_permit'),
            value: translateValue(booking.house.building.buildingPermit)
          },
          {
            label: t('pages.booking_summary.project_info.usage_permit'),
            value: translateValue(booking.house.building.usagePermit)
          },
          hasValue(booking.house.floor)
            ? {
                label: t('pages.booking_summary.house_details.floor'),
                value: booking.house.floor
              }
            : undefined
        ].filter(hasValue)
      ),
    [
      t,
      translateValue,
      booking,
      getFormsOfOwnership.data,
      getPropertyTypes.data,
      getEnergyClasses.data,
      getProjectSettings.data
    ]
  )

  return { detailItems }
}
