import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Booking, BookingState } from '@api/endpoints/bookings'
import { useGetBookingItem } from '@api/endpoints/buildings/houses'
import { eqOptionValueIdArray, getSortedOptionValueIds } from '@api/endpoints/buildings/options'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { Alert, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Button } from '@controls/Button'
import { useAdminContext } from '@pages/Admin/AdminContext'
import { BookingOptionEdit } from '@pages/Customer/BookingSummary/BookingOptionEdit'
import { BookingOptions } from '@pages/Customer/BookingSummary/BookingOptions'
import { BookingPrice } from '@pages/Customer/BookingSummary/BookingPrice'
import { BookingStyleEdit } from '@pages/Customer/BookingSummary/BookingStyleEdit/BookingStyleEdit'
import { OfferBy } from '@pages/Customer/BookingSummary/OfferBy'
import { useEditBookingStore } from '@pages/Customer/BookingSummary/useEditBookingStore'
import { BookingImages } from './BookingImages'
import { BookingStyle } from './BookingStyle'
import { HouseDetails } from './HouseDetails'
import { hasSelectedBookingOptionsErrors } from './utils'

type Params = {
  booking: Booking
  disabledControls: boolean
  onSubmitEdit: React.DispatchWithoutAction
}

export const BookingSummaryContent: React.FC<Params> = ({
  booking,
  disabledControls: disabledParentControls,
  onSubmitEdit
}) => {
  const { isLoggedIn } = useAdminContext()
  const { t } = useTranslation()
  const defaultErrorHandler = useDefaultErrorHandler()

  const setAvailableHouseOptions = useEditBookingStore((s) => s.setAvailableHouseOptions)
  const setBookingOptions = useEditBookingStore((s) => s.setBookingOptions)
  const isEditing = useEditBookingStore((s) => s.editing)
  const editingOption = useEditBookingStore((s) => s.editingOption)
  const hasValidationErrors = useEditBookingStore((s) =>
    hasSelectedBookingOptionsErrors(s.selectedOptions, s.editingOption)
  )
  const startEdit = useEditBookingStore((s) => s.startEdit)
  const cancelEdit = useEditBookingStore((s) => s.cancelEdit)
  const updatedOptionsCount = useEditBookingStore((s) => s.updatedOptionsCount)
  const bookingLayoutTypeId = useEditBookingStore((s) => s.bookingOptions.style?.layoutTypeStyleId)
  const bookingOptionValueIds = useEditBookingStore((s) => getSortedOptionValueIds(s.bookingOptions.options))
  const setSelectedOptions = useEditBookingStore((s) => s.setSelectedOptions)
  const setSelectedLayoutTypeStyleId = useEditBookingStore((s) => s.setSelectedLayoutTypeStyleId)

  const getBookingItem = useGetBookingItem(booking.house.id, {
    onError: defaultErrorHandler,
    enabled: isEditing
  })

  const canEdit: boolean = isLoggedIn || booking.state === BookingState.enum.Draft
  const disabledControls = disabledParentControls || (isEditing && getBookingItem.isLoading)

  React.useEffect(() => {
    setBookingOptions({ options: booking.options, style: booking.style })

    if (
      !eqOptionValueIdArray.equals(getSortedOptionValueIds(booking.options), bookingOptionValueIds) ||
      booking.style.layoutTypeStyleId !== bookingLayoutTypeId
    ) {
      cancelEdit()
      setSelectedOptions(booking.options)
      setSelectedLayoutTypeStyleId(booking.style.layoutTypeStyleId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.options, booking.style])

  React.useEffect(() => {
    if (getBookingItem.data) {
      setAvailableHouseOptions({ options: getBookingItem.data.options, styles: getBookingItem.data.styles })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBookingItem.data])

  return (
    <>
      <Grid item xs={12} sm={9}>
        {isLoggedIn && (
          // TODO: Change translation to pages.booking-summary.*
          <Alert severity="info">{t('pages.booking_summary.messages.updating_by_admin')}</Alert>
        )}
        <HouseDetails booking={booking} disabledControls={disabledControls} />

        {isEditing ? (
          <BookingStyleEdit disabledControls={disabledControls}>
            <Box mb={3}>
              <Box display="flex" alignContent="space-between" flexDirection="row" columnGap={2}>
                <Button
                  onClick={onSubmitEdit}
                  disabled={disabledControls || updatedOptionsCount === 0 || hasValidationErrors}
                >
                  {t('pages.booking_summary.style.buttons.submit') + ` (${updatedOptionsCount})`}
                </Button>
                <Button onClick={cancelEdit} disabled={disabledControls}>
                  {t('pages.booking_summary.style.buttons.cancel')}
                </Button>
              </Box>
            </Box>
          </BookingStyleEdit>
        ) : (
          <BookingStyle style={booking.style}>
            {canEdit && (
              <Box mb={3}>
                <Button onClick={startEdit} disabled={disabledControls}>
                  {t('pages.booking_summary.style.buttons.edit')}
                </Button>
              </Box>
            )}
          </BookingStyle>
        )}

        {editingOption ? (
          <BookingOptionEdit option={editingOption} disabledControls={disabledControls} />
        ) : (
          <BookingOptions freeOptions={booking.house.freeOptions} disabledControls={disabledControls} />
        )}
        {!isEditing && (
          <>
            <BookingPrice booking={booking} />
            <OfferBy />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <BookingImages house={booking.house} />
      </Grid>
    </>
  )
}
