import styled, { css } from 'styled-components'

export const BookingPriceStyled = styled('div')(
  ({ theme }) => css`
    //margin: ${theme.spacing(7, 0)};
    /*
    display: flex;
    align-items: center;
    column-gap: ${theme.spacing(6)};
    

    ${theme.breakpoints.down('sm')} {
      text-align: center;
      flex-direction: column;
      row-gap: ${theme.spacing(2)};
    }    
    */
  `
)
