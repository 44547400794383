import styled, { css } from 'styled-components'

export const BookingSuccessPanelStyled = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.brown};
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${theme.spacing(2)};

    a {
      font-size: ${theme.global.typography.size['size-14']};
      text-decoration-color: currentColor;
      //color: ${theme.colors.brown};
    }

    svg {
      font-size: ${theme.global.typography.size['size-24']};
    }
  `
)
