import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { routes } from '@constants/routes'
import { BookingId } from '@api/endpoints/bookings'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'
import { BookingSuccessPanelStyled } from '@pages/Customer/BookingSuccessPanel/styles'
import { CircleCheckIcon } from '@icons/CircleCheckIcon'

type Props = Readonly<{
  bookingId: BookingId
}>

export const BookingSuccessPanel: React.FC<Props> = ({ bookingId }) => {
  const { t } = useTranslation()
  return (
    <BookingSuccessPanelStyled>
      <CircleCheckIcon />
      <Text weight="regular" textAlign="center">
        {t('pages.booking.form.booking_success')}
      </Text>
      <Link
        // eslint-disable-next-line functional/immutable-data
        onClick={() => (document.location = generatePath(routes.Customer.BookingSummary, { bookingId: bookingId }))}
      >
        {t('pages.booking.form.view_booking_summary')}
      </Link>
    </BookingSuccessPanelStyled>
  )
}
