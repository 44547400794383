import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { Lazy } from '@digital-magic/ts-common-utils'
import { formatDateShort } from '@utils/date-utils'
import { downloadFile } from '@utils/file-utils'
import { language } from '@utils/language-utils'
import { Booking } from '@api/endpoints/bookings'
import { bookingUrl } from '@api/endpoints/bookings/api'
import { useSnackbar } from 'notistack'
import { Download } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import { Button } from '@controls/Button'
import { DetailList } from '@controls/DetailList'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'
import { HouseDetailsStyled } from './HouseDetails.styles'
import { useHouseData } from './useHouseData'

type Props = {
  booking: Booking
  disabledControls: boolean
}

export const HouseDetails: React.FC<Props> = ({ booking, disabledControls }) => {
  const { t, i18n } = useTranslation()
  const snackbar = useSnackbar()
  const houseData = useHouseData(booking)

  const downloadPdf: Lazy<Promise<void>> = () =>
    downloadFile(`${bookingUrl(booking.id)}/pdf?language=${language(i18n.language)}`)

  const onCopiedHandler = (): void => {
    snackbar.enqueueSnackbar(t('pages.booking_summary.messages.link_copied'), { variant: 'success' })
  }

  return (
    <HouseDetailsStyled>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Text display="block" color="brown" size="size-34">
          {t('pages.booking_summary.title', { bookingNumber: booking.bookingNumber })}
        </Text>
        {booking.state === 'Submitted' && (
          <>
            <div>
              <CopyToClipboard text={document.documentURI} onCopy={onCopiedHandler}>
                <Link href="#">{t('pages.booking_summary.buttons.copy_link')}</Link>
              </CopyToClipboard>
            </div>
            <Button
              size="small"
              className="download-button"
              onClick={downloadPdf}
              title={t('global.buttons.download')}
              disabled={disabledControls}
            >
              <Download />
            </Button>
          </>
        )}
      </Box>
      <Text display="block" size="size-20" weight="semibold" mb={4}>
        {t('pages.booking_summary.status.label') +
          ': ' +
          (booking.state === 'Draft'
            ? t('pages.booking_summary.status.enum.draft')
            : t(`pages.booking_summary.status.enum.${booking.status.toLowerCase()}`))}
      </Text>
      <Grid container columnSpacing={2.5} rowSpacing={2} mb={4} fontSize={16}>
        <Grid item xs={6}>
          {t('pages.booking_summary.dates.created_at') + ': '}
        </Grid>
        <Grid item xs={6} textAlign="right">
          {formatDateShort(booking.createdAt)}
        </Grid>
        <Grid item xs={6}>
          {t('pages.booking_summary.dates.valid_until') + ': '}
        </Grid>
        <Grid item xs={6} textAlign="right">
          {formatDateShort(booking.expiresAt)}
        </Grid>
      </Grid>

      <DetailList items={houseData.detailItems} alignValue="right" />
    </HouseDetailsStyled>
  )
}
