import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue } from '@digital-magic/ts-common-utils'
import { priceCurrency } from '@constants/configuration'
import { BookingStyle as BookingStyleModel } from '@api/endpoints/bookings'
import { fileUrl, getLargeThumbnailId } from '@api/endpoints/files'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { Grid } from '@mui/material'
import { ImageGallery, ImageItem } from '@controls/ImageGallery'
import { Text } from '@controls/Text'
import { BookingStyleStyled } from './BookingStyle.styles'

type Props = React.PropsWithChildren & {
  style: BookingStyleModel
}

export const BookingStyle: React.FC<Props> = ({ style, children }) => {
  const { t, i18n } = useTranslation()
  const formatCurrency = useCurrencyFormatter()

  const mainImage = React.useMemo(() => style.images.find((v) => v.primaryImage) ?? style.images[0], [style.images])
  const restImages = React.useMemo(
    () => style.images.filter((v) => !v.primaryImage).sort((a, b) => a.orderNumber - b.orderNumber),
    [style.images]
  )

  //const [firstStyleImage, ...restStyleImages] = style.images ?? []

  const stylePrice = React.useMemo((): string | undefined => {
    const numberPrice = Number(style.additionalPrice)

    if (numberPrice > 0) {
      return formatCurrency(numberPrice, priceCurrency)
    }

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style.additionalPrice, i18n.language])

  return (
    <BookingStyleStyled>
      <Text display="block" color="brown" size="size-34" my={4}>
        {t('pages.booking_summary.style.title')}
      </Text>
      {children}
      <div className="styling-card">
        <Grid container columnSpacing={2.5} rowSpacing={2}>
          <Grid item xs={12} sm={4}>
            {hasValue(mainImage) && (
              <ImageGallery selector=".style-img">
                <ImageItem src={fileUrl(getLargeThumbnailId(mainImage))} className="style-img" />
                {restImages.map((img, index) => (
                  <ImageItem key={index} src={fileUrl(getLargeThumbnailId(img))} className="style-img" hidden />
                ))}
              </ImageGallery>
            )}
          </Grid>
          <Grid item xs={12} sm={8} className="styling-details">
            <Text weight="regular">{t('pages.booking_summary.style.styling_package')}</Text>
            <Text size="size-30" weight="regular">
              {t(`enums.style_type.${style.styleType}`)}
            </Text>
            {stylePrice && <Text weight="regular">{stylePrice}</Text>}
          </Grid>
        </Grid>
      </div>
    </BookingStyleStyled>
  )
}
